<template>
  <v-container>
    <v-card
      outlined
      rounded="lg"
    >
      <v-toolbar flat>
        <v-toolbar-title>{{ printer.title }}</v-toolbar-title>
        <v-spacer />
        <v-tooltip
          v-if="!hasMacAddress"
          bottom
        >
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-btn
                outlined
                class="my-4 mx-4"
                width="135px"
                elevation="0"
                disabled
              >
                {{ $t('clearQueue') }}
              </v-btn>
            </div>
          </template>
          {{ $t('printerIsMissingMacAddress') }}
        </v-tooltip>
        <v-btn  
          v-else
          outlined
          class="my-4 mx-4"
          width="135px"
          @click="clearPrinterQueue"
          elevation="0"
        >
          {{ $t('clearQueue') }}
        </v-btn>
      
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-btn
                v-if="!updatePrinter && !printerFormValid"
                color="success"
                class="my-4"
                width="135px"
                elevation="0"
                disabled
              >
                {{ $t('save') }}
              </v-btn>
            </div>
          </template>
          {{ $t('missingRequiredValuesOrValuesAreIncorrect') }}
        </v-tooltip>
        <v-btn
          v-if="!updatePrinter && printerFormValid"
          color="success"
          class="my-4"
          width="135px"
          @click="triggerUpdatePrinter"
          elevation="0"
        >
          {{ $t('save') }}
        </v-btn>
        <v-btn
          v-if="updatePrinter"
          color="success"
          disabled
          width="135px"
        >
          <v-progress-circular
            indeterminate
          />
          <v-icon />
        </v-btn>
      </v-toolbar>
    </v-card>
    <v-row>
      <v-col>
        <PrinterForm 
          :value="printerToUpdate"
          :trigger-update="updatePrinter"
          @finishedUpdating="updatePrinter = false"
          @valid="setPrinterFormValidity"
          @updateProfile="updateProfile"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PrinterForm from "../printers/PrinterForm.vue"
export default {
	name: 'PrinterProfile',
	props: {
		printer: {
			type: Object,
			required: true,
			default: () => {}
		}
	}, 
	components: {
		PrinterForm
	},
	data() {
		return {
			updatePrinter: false,
			printerFormValid: false
		}
	},
  computed: {
    printerToUpdate() {
      return JSON.parse(JSON.stringify(this.printer))
    }, 
    hasMacAddress() {
      if(this.printer.macAddress) {
        return true
      }
      return false
    }
  },
	methods: {
    clearPrinterQueue() {
      this.$store.dispatch('clearPrinterQueue', this.printer.macAddress).then(result => {
        if(result && typeof result.modifiedCount !== 'undefined' && typeof result.matchedCount !== 'undefined') {
          this.$store.commit('updateActionSuccess', {
            message: this.$t('success'), 
            subMessage: this.$t('foundQueuedMessagesdModifiedMessages', {modifiedCount: result.modifiedCount, matchedCount: result.matchedCount})
          })
        } else {
          this.$store.commit('updateActionError', {
            message: this.$t('error'), 
            subMessage: this.$t('somethingWentWrong')
          })
        }
      }).catch(() => {
        this.$store.commit('updateActionError', {
            message: this.$t('error'), 
            subMessage: this.$t('somethingWentWrong')
        })
      })
    },
		triggerUpdatePrinter() {
			this.updatePrinter = true 
		}, 
    setPrinterFormValidity(value) {
      this.printerFormValid = value
    }, 
    updateProfile(updatedValues) {
      for(const key in updatedValues) {
        this.printer[key] = updatedValues[key]
      }
    }
	},
}
</script>
